@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap');

@import 'normalize';
@import 'variables';
@import 'headings';
@import 'containers';
@import 'forms';
@import 'table';
@import 'texts';
@import 'hero';
@import 'header-bar';
