.header-bar {
  display: none; /* flex */
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.625rem;
  padding-top: 0.625rem;
  gap: 0.5rem;
  text-align: center;
  background-color: blueviolet;
  color: white;

  .ionicon {
    fill: white;
    width: 1rem;
  }

  a {
    color: white;
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    display: block;
    padding: 0.625rem 1rem;
  }
}
